import { ContainerAbout, ContainerImgText, ContainerText } from "./Container";
import { Image } from "./Image";
import { AboutText, SocialMediaText } from "../../global/typography";
import { logoAbout } from "../../assets"
import { backgroundColors } from "../../global/colors";
import { InstagramIcon, MailIcon, WhattsappIcon } from "./Icons"
import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";
import { Link } from "@mui/material"
import { colors } from "../../global";

const ContainerContact = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const ContainerSocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
background-color: ${backgroundColors.purple[1]};
@media(min-width: ${breakpoints.desktop}) {
  padding: 64px 64px 80px 64px;
}
`

const ContactLink = styled(Link)`
cursor: pointer;
`

export const About = () => {
  return (
    <Wrapper>
      <ContainerAbout id="about" startColor={backgroundColors.purple[1]} endColor={backgroundColors.purple[1]}>
        <ContainerImgText>
          <Image src={logoAbout}></Image>
          <ContainerSocialMedia>
            <ContainerContact>
              <MailIcon />
              <SocialMediaText>
                contato.vendas.ex@gmail.com
              </SocialMediaText>
            </ContainerContact>

            <ContainerContact>
              <WhattsappIcon />
              <SocialMediaText>
                (99) 99999-9999
              </SocialMediaText>
            </ContainerContact>

            <ContainerContact>
              <InstagramIcon />
              <SocialMediaText>
                @exemplo_lamado
              </SocialMediaText>
            </ContainerContact>
          </ContainerSocialMedia>
        </ContainerImgText>
        <ContainerText>
          <AboutText>Com 12 anos de experiência como representante comercial na área de materiais de construção e tintas, meu objetivo é oferecer produtos de qualidade e soluções eficientes para o seu projeto. Aqui, você encontra atendimento personalizado e condições especiais de vendas, sempre com foco em suas necessidades e no melhor custo-benefício.</AboutText>
          <AboutText>
            <ContactLink color={colors.lightText} onClick={() => { window.location.href = "#contact" }}>Entre em contato</ContactLink> para saber mais e receber uma consultoria especializada!
          </AboutText>
        </ContainerText>
      </ContainerAbout>
    </Wrapper>
  )
}