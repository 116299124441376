import { Container, ContainerBrands, ContainerLogo } from "./Container";
import { Title, Subtitle, HomeBrandText, NavButtonText, HomeActionText } from "../../global/typography"
import { logoSinteplast, logo2001, logoSulan, logoCastor, logoTytan } from "../../assets"
import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import { Button } from "../button";
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import { colors } from "../../global";
import { Link } from "@mui/material"




const logoList = [{ logo: logoSinteplast, id: "#sinteplast" }, { logo: logoSulan, id: "#sulan" }, { logo: logo2001, id: "#2001" }, { logo: logoTytan, id: "#tytan" }, { logo: logoCastor, id: "#castor" }];

type Responsive = {
  mobile: string
  desktop: string
}

const Brand = styled.img<{ customWidth: Responsive, customHeight: Responsive }>`
  display: block;
  /* width: 80px;
  height: 40px; */
  height: ${(({ customHeight: height }) => height.mobile)};
  width: ${(({ customWidth: width }) => width.mobile)};
  @media(min-width: ${breakpoints.desktop}){

    height: ${(({ customHeight: height }) => height.desktop)};
  width: ${(({ customWidth: width }) => width.desktop)};
  }
`

const SubtitleContainer = styled.div`
display: flex;
flex-direction: column;
gap:16px;
@media(min-width: ${breakpoints.desktop}) {
  min-width: 40%;
  max-width: 40%;
  padding: 16px;
  border: 2px ${colors.titleText} solid;
    border-radius: 10px;
    background-color: #ffffffbb;
}
`

const TextContainer = styled.div`
display: flex;
flex-direction: column;
gap: 32px;
@media(min-width: ${breakpoints.desktop}) {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
`

const Brands = styled.div`
display: flex;
flex-direction: column;
@media(min-width: ${breakpoints.desktop}) {
    min-width: 60%;
    max-width: 60%;
    padding-left: 32px;
    border-radius: 20px 0 0 0;
  }
`

const PremiumIcon = styled(WorkspacePremiumTwoToneIcon)`
display: none;
@media(min-width: ${breakpoints.desktop}) {
  display: block;
  width: 48px;
  height: 56px;
  fill: #e6bc13;
}
`

const IconContainer = styled.div`
display: none;
@media(min-width: ${breakpoints.desktop}) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50%;
  max-height: 50%;
}
`

const LogoWrapper = styled.div`
@media(min-width: ${breakpoints.desktop}) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
`

const StoreIcon = styled(ShoppingCartTwoToneIcon)`
fill: #f2f2f2;
`

const ButtonContainer = styled.div`
display: flex;
gap: 2px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content:center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 32px;
  padding-top: 10px;
  gap: 16px;
  @media(min-width: ${breakpoints.tablet}){
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    justify-content:left;

  }
`
const ActionLink = styled(Link)`
:hover {
  cursor: pointer;

}

`

const LineBreak = styled.br`
@media(min-width: ${breakpoints.desktop}) {
  display: none;
}
`

const Wrapper = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`

export const StartScreen = () => {
  const imgMockSizes: { width: Responsive, height: Responsive }[] = [
    { width: { mobile: "120px", desktop: "120px" }, height: { mobile: "56px", desktop: "56px" } }, // sinteplast
    // { width: { mobile: "120px", desktop: "120px" }, height: { mobile: "40px", desktop: "40px" } }, // recubriplast
    { width: { mobile: "120px", desktop: "120px" }, height: { mobile: "35px", desktop: "35px" } }, // sulan
    { width: { mobile: "120px", desktop: "120px" }, height: { mobile: "34px", desktop: "34px" } }, // 2001
    { width: { mobile: "92px", desktop: "96px" }, height: { mobile: "45px", desktop: "50px" } }, // tytan
    { width: { mobile: "120px", desktop: "120px" }, height: { mobile: "40px", desktop: "38px" } }, // castor
  ]

  return (
    <Wrapper>
      <Container id="startScreen">

        <Title>
          Qualidade e variedade
          em impermeabilizantes, tintas, ferramentas
          e muito mais.
        </Title>
        <TextContainer>
          <SubtitleContainer>
            <Subtitle>
              Trabalhamos com um portfólio de 5 marcas líderes em Impermeabilizantes, Tintas, Pincéis, Rolos, Silicones e muito mais, sempre garantindo a qualidade para atender às mais diversas necessidades.
            </Subtitle>
            <Subtitle>
              Nossa seleção garante produtos de alto desempenho, com soluções eficazes para proteção e durabilidade em qualquer tipo de aplicação.
            </Subtitle>
            <ButtonWrapper>
              <Button width="160px" backgroundColor={colors.menuText}><ButtonContainer><StoreIcon /><NavButtonText>Ir para a loja</NavButtonText></ButtonContainer></Button>
              <ActionLink color="#555555" onClick={() => { window.location.href = "#contact" }}><HomeActionText>Ainda não tem cadastro? <LineBreak></LineBreak>Entre em contato para saber mais.</HomeActionText></ActionLink>
            </ButtonWrapper>
          </SubtitleContainer>

          <Brands>
            <HomeBrandText>Conheça nossas marcas</HomeBrandText>
            <ContainerBrands>
              {logoList.map((item, index) => {
                return (
                  <ContainerLogo index={index} onClick={() => window.location.href = item.id}>
                    <LogoWrapper>
                      <Brand customWidth={imgMockSizes[index].width} customHeight={imgMockSizes[index].height} src={item.logo} />
                    </LogoWrapper>
                    {/* <IconContainer><PremiumIcon /></IconContainer> */}
                  </ContainerLogo>

                )
              })}
            </ ContainerBrands>
          </Brands>
        </TextContainer>
      </Container>
    </Wrapper>
  )
}