import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";

export const CarouselImage = styled.img`
  height: 120px;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
  @media(min-width: ${breakpoints.desktop}) {
    width: 100%;
    border-radius: 0;
  }
`