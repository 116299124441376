import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import { ImgList } from "./CarouselItems";
import { Image } from "./Image";
import { Container } from "./Container";

function Item(props: { key: number, item: any }) {
  return (
    <Paper>
      <Image src={props.item}></Image>
    </Paper>
  )
}

export const PromotionCarousel = () => {
  return (
    <Container>
      <Carousel>
        {
          ImgList.map((item, i) => <Item key={i} item={item} />)
        }
      </Carousel>
    </Container>
  )
}

