import emailjs from "@emailjs/browser";

export const SendEmail = (e: any) => {
  e.preventDefault();
  emailjs
    .sendForm('service_wd39evm', 'template_672xfqg', e.target, {
      publicKey: 'rSCOEyMpra-N7S2gj',
    })
    .then(
      () => {
        alert('SUCCESS!');
      },
      (error) => {
        alert('FAILED... ' + error.text);
      },
    );
};