import React, { useState } from "react"
import styled from "@emotion/styled"
import { Container } from "./Container"
import { Logo } from "./Logo"
import { ButtonText, NavbarText, NavButtonText } from "../../global/typography"
import { Menu } from "./Menu"
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { breakpoints } from "../../global/breakpoints"
import { colors } from "../../global"
import { Link, Button } from "@mui/material"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import PermIdentityTwoToneIcon from '@mui/icons-material/PermIdentityTwoTone';




const BrandMenuLabel = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const MenuWrapper = styled.nav`
  position: sticky;
  top: 0;
  z-index: 99999;
  background-color: ${colors.white};
  width: 100%;
`

const MenuIcon = styled(MenuRoundedIcon)`
fill: ${colors.menuText};
@media(min-width: ${breakpoints.desktop}) {
  display: none;
}
`

const DesktopBrandMenu = styled.ul`
display: none;

@media(min-width: ${breakpoints.desktop}) {
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
} 
`

const NavLink = styled(Link)`
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
gap: 8px;
:hover{
  color: ${colors.menuText}BB;
}
`

const DesktopSideMenu = styled.ul`
display: none;

@media(min-width: ${breakpoints.desktop}) {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
} 
`

const SendIcon = styled(SendRoundedIcon)`
width: 18px;
height: 18px;
fill: ${colors.lightText};
position: relative;
top: 0;
`

const NavButton = styled(Button)`
background-color: ${colors.menuText};
text-transform: none;
display: flex;
gap: 8px;
padding: 8px 10px;
justify-content: center;
align-items: center;
`
const StoreIcon = styled(ShoppingCartTwoToneIcon)`
fill: currentColor;
`

const AboutIcon = styled(PermIdentityTwoToneIcon)`
fill: currentColor;
`

const IconTextWrapper = styled.div`
display: flex;
gap: 2px;
justify-content: center;
align-items: center;

`

export const Navbar = () => {
  const [menuVisible, toggleMenuVisible] = useState(false);
  return (
    <MenuWrapper>
      <Container>
        <Logo />
        <MenuIcon onClick={() => toggleMenuVisible(!menuVisible)} />
        <DesktopBrandMenu>
          <li><NavLink underline="hover" color={colors.menuText} onClick={() => { window.location.href = "#sinteplast" }}><NavbarText>Sinteplast (Recubriplast)</NavbarText></NavLink></li>
          <li><NavLink underline="hover" color={colors.menuText} onClick={() => { window.location.href = "#sulan" }}><NavbarText>Sulan</NavbarText></NavLink></li>
          <li><NavLink underline="hover" color={colors.menuText} onClick={() => { window.location.href = "#2001" }}><NavbarText>2001</NavbarText></NavLink></li>
          <li><NavLink underline="hover" color={colors.menuText} onClick={() => { window.location.href = "#tytan" }}><NavbarText>Tytan</NavbarText></NavLink></li>
          <li><NavLink underline="hover" color={colors.menuText} onClick={() => { window.location.href = "#castor" }}><NavbarText>Castor</NavbarText></NavLink></li>
        </DesktopBrandMenu>
        <DesktopSideMenu>
          <li><NavLink underline="hover" color={colors.menuText} onClick={() => { window.location.href = "#about" }}>
            <IconTextWrapper>
              <AboutIcon />
              <NavbarText>Sobre</NavbarText>
            </IconTextWrapper>
          </NavLink></li>
          <li><NavLink underline="hover" color={colors.menuText} onClick={() => { window.location.href = "#home" }}>
            <IconTextWrapper>
              <StoreIcon />
              <NavbarText>Loja</NavbarText>
            </IconTextWrapper>
          </NavLink></li>

          <li>
            <NavButton type="button" onClick={() => { window.location.href = "#contact" }}>
              <NavButtonText>Entre em contato</NavButtonText>
              <SendIcon />
            </NavButton>
          </li>
        </DesktopSideMenu>
      </Container>
      <Menu visible={menuVisible} toggle={toggleMenuVisible} />
    </MenuWrapper>
  )
}