import styled from "@emotion/styled";
import Carousel from "react-multi-carousel"
import { CarouselImage } from "./CarouselImage";
import { brandsImgList } from "../promotionCarousel/CarouselItems";
import 'react-multi-carousel/lib/styles.css';
import { ProductText } from "../../global/typography";
import "./carousel.css";
import { breakpoints } from "../../global/breakpoints";


const CarouselContainer = styled.div`
width: 100%;
max-width: 80vw;
height: max-content;
position: relative;
  margin: 0 auto;
  padding-bottom: 30px;
@media(min-width: ${breakpoints.tablet}){
  max-width: 90vw;
}
`
const CardTemplate = styled.div<{ backgroundColor: string }>`
background-color: ${({ backgroundColor }) => backgroundColor};
display: flex;
align-items: center;
justify-content: flex-start;
flex-direction: column;
gap: 8px;
width: 160px;
height: 210px;
padding: 0px 0px 0px 0px;
border-radius: 12px;
border: #00000055 2px solid;
@media(min-width: ${breakpoints.desktop}) {
  width: 180px;
  border-radius: 2px;
}
`

const ImageWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 12px 12px 0 0;
  @media(min-width: ${breakpoints.desktop}){
    border-radius: 0;
  }
`
const TextWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledCard = (props: { item: string, name: string, backgroundColor: string }) => {
  return (
    <CardTemplate backgroundColor={props.backgroundColor}>
      <ImageWrapper><CarouselImage src={props.item} /></ImageWrapper>
      <TextWrapper><ProductText>{props.name}</ProductText></TextWrapper>
    </CardTemplate>
  )
}


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};


export const BrandCarousel = (props: { cardBackground: string, dotListClass: string }) => {
  return (
    <CarouselContainer>
      <Carousel responsive={responsive}
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className="carousel"
        containerClass="container"
        dotListClass={props.dotListClass}
        draggable
        focusOnSelect={false}
        infinite={true}
        itemClass="card"
        keyBoardControl
        minimumTouchDrag={50}
        pauseOnHover
        renderArrowsWhenDisabled={true}
        renderButtonGroupOutside={true}
        renderDotsOutside={true}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={true}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >

        {brandsImgList.map((item) => <StyledCard item={item} name="produto teste" backgroundColor={props.cardBackground} />)}
      </Carousel>
    </CarouselContainer>
  )
}

const Container = styled.div`
align-items: center;
justify-content: center;
height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`
const Row = styled.div`
  gap: 32px;
  display: flex;
  padding-left: 32px;
  justify-content: space-between;
  width: 100%;
`

export const ProductDisplay = (props: { productList: { name: string, img: string }[], cardBackground: string }) => {
  return (
    <Container>
      {brandsImgList.map((item, index) => {
        return (
          index % 3 === 0 && <Row>
            <StyledCard item={brandsImgList[index]} name="produto teste" backgroundColor="#FFFFFF88" />
            {brandsImgList[index + 1] && <StyledCard item={brandsImgList[index + 1]} name="produto teste" backgroundColor="#FFFFFF88" />}
            {brandsImgList[index + 2] && <StyledCard item={brandsImgList[index + 2]} name="produto teste" backgroundColor="#FFFFFF88" />}
          </Row>
        )
      })}
    </Container>
  )
}