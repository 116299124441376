import styled from "@emotion/styled";
import { BrandText, ProductText } from "../../global/typography"
import { OpenCatalogButton } from "./OpenCatalogButton"
import { breakpoints } from "../../global/breakpoints";
import { BrandCarousel, ProductDisplay } from "./BrandCarousel";
import { brandsImgList } from "../promotionCarousel/CarouselItems";

const Wrapper = styled.div<{ background: string, noPadding: boolean }>`
  background: ${({ background }) => background};
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(min-width: ${breakpoints.desktop}) {
    padding: 0px 64px 64px 64px;
    ${(({ noPadding }) => noPadding ? "padding-bottom: 0px" : "")}

  }
`

const BrandContainer = styled.div<{ background: string, borderColor: string }>`
  padding: 48px 32px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  gap: 32px;
  @media(min-width: ${breakpoints.tablet}) {
    align-items: flex-start;
    padding: 48px 32px;

  }
  @media(min-width: ${breakpoints.desktop}) {
    padding: 64px 64px;
    gap: 32px;
    align-items: flex-start;
    border-radius: 10px;
    border: 4px ${(({ borderColor }) => borderColor)} solid;
    background-color: ${({ background }) => background};
    /* filter: drop-shadow(-4px 4px 8px); */
  max-width: 1372px;
  }
`
type TwoDimensionalSize = {
  width: string
  height: string
}

type ResponsiveLogo = {
  mobile: TwoDimensionalSize
  desktop: TwoDimensionalSize
}

const Logo = styled.img<{ size: ResponsiveLogo }>`
  width: ${(({ size }) => size.mobile.width)};
  height: ${(({ size }) => size.mobile.height)};
`
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(min-width: ${breakpoints.tablet}) {
    justify-content: flex-start;
  }
  @media(min-width: ${breakpoints.desktop}) {
    justify-content: flex-start;
  }
`

const BrandInfo = styled.div<{ borderColor: string }>`
min-width: 100%;
max-width: 100%;
display: flex;
flex-direction: column;
gap: 32px;
align-items: center;
@media(min-width: ${breakpoints.tablet}) {
  align-items: flex-start;
}
@media(min-width: ${breakpoints.desktop}) {
  min-width: 40%;
  max-width: 40%;
  padding-right: 32px;

} 
`

const Content = styled.div`
width: 100%;
display: flex;
flex-direction: column;
@media(min-width: ${breakpoints.desktop}) {
  flex-direction: row;
} 
`

const CarouselWrapper = styled.div`
width: 100%;
@media(min-width: ${breakpoints.desktop}) {
  display: none;
}
`


const LogoTextContainer = styled.div`
display: flex;
flex-direction: column;
gap: 32px;
justify-content: center;
align-items: center;

@media(min-width: ${breakpoints.tablet}) {
  align-items: flex-start;
}
`
const ProductDisplayWrapper = styled.div`
display: none;
width: 100%;
@media(min-width: ${breakpoints.desktop}) {
  display: block;
}
`

export const BrandSection = (props: { startColor: string, endColor: string, breakpoint: number, textList: string[], buttonColor: string, Logo: string, LogoSize: ResponsiveLogo, cardBackground: string, dotListClass: string, id: string, borderColor: string, noPadding?: boolean }) => {
  return (
    <Wrapper background={`linear-gradient(180deg, ${props.startColor} ${props.breakpoint}%, ${props.endColor} 100% )`} noPadding={props.noPadding ? true : false}>
      <BrandContainer id={props.id} background="#ffffffaa" borderColor={props.borderColor}>
        <Content>
          <BrandInfo borderColor={props.startColor}>
            <LogoTextContainer>
              <Logo src={props.Logo} size={props.LogoSize} />

              {props.textList.map(text => <BrandText>{text}</BrandText>)}
            </LogoTextContainer>
            <CarouselWrapper>
              <BrandCarousel cardBackground={props.cardBackground} dotListClass={props.dotListClass} />
            </CarouselWrapper>
            <ButtonContainer>
              <OpenCatalogButton backgroundColor={props.buttonColor} />
            </ButtonContainer>
          </BrandInfo>
          <ProductDisplayWrapper>
            <ProductDisplay productList={brandsImgList.map((img) => { return { name: "produto teste", img } })} cardBackground={props.cardBackground} />
          </ProductDisplayWrapper>
        </Content>

      </BrandContainer>
    </Wrapper >
  )
}