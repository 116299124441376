import styled from "@emotion/styled";
import { ButtonText, ContactFormText } from "../../global/typography";
import { Button } from "../button";
import { SendIcon } from "./Icons";
import { breakpoints } from "../../global/breakpoints";
import { SendEmail } from "./SendEmail";
import { colors } from "../../global";


const InputText = styled.input`
  display: block;
  height: 27px;
  width: 70%;
  background-color: #F2F2F2;
  border-radius: 5px;
  border: none;
  float: left;
  @media(min-width: ${breakpoints.tablet}){
    width: 85%;
  }
  @media(min-width: ${breakpoints.desktop}){
    width: 90%;
  }
`

const InputTextArea = styled.textarea`
  display: block;
  width: 60%;
  max-width: 70%;
  min-width: 70%;
  border-radius: 5px;
  background-color: #F2F2F2;
  border: none;
  min-height: 128px;
  float: left;
  @media(min-width: ${breakpoints.tablet}){
    width: 85%;
    max-width: 85%;
    min-width: 85%;
  }
  @media(min-width: ${breakpoints.desktop}){
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
`

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  inputType: number;
  name: string;
}

const InputGroup = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  @media(min-width: ${breakpoints.desktop}){
    justify-content: space-between;
    
  }
`;

const ContainerForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ContainerButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
`

const Input: React.FC<InputProps> = ({ id, name, label, inputType, ...rest }) => {
  if (inputType === 1) {
    return (
      <InputGroup>
        <ContactFormText htmlFor={id} >{label}</ContactFormText>
        <InputTextArea id={id} name={name} />
      </InputGroup>
    );
  } else {
    return (
      <InputGroup>
        <ContactFormText htmlFor={id} >{label}</ContactFormText>
        <InputText id={id} name={name} {...rest} />
      </InputGroup>
    );
  }
}


export const Form = () => {
  return (
    <ContainerForm onSubmit={SendEmail}>
      <Input id="name" name="name" type="text" inputType={0} label="Nome: " />
      <Input id="subject" name="subject" type="text" inputType={0} label="Assunto: " />
      <Input id="email" name="email" type="text" inputType={0} label="Email: " />
      <Input id="message" name="message" type="text" inputType={1} label="Mensagem: " />
      <ContainerButton>
        <Button type="submit" backgroundColor={colors.buttonColors.green} width="199px"><ButtonText>Enviar</ButtonText> <SendIcon /></Button>
      </ContainerButton>
    </ContainerForm>
  )

}