import styled from "@emotion/styled";
import { Button } from "../button"
import { ButtonText } from "../../global/typography"
import OpenIcon from "../../assets/openNew.svg"

export const OpenCatalogButton = (props: { backgroundColor: string }) => {

  const Icon = styled.img`
  width: 16px;
  height: 16px;
  `
  const Container = styled.div`
  display: flex;
  gap:8px;
  `

  return (
    <Button backgroundColor={props.backgroundColor} width="180px">
      <Container>
        <ButtonText>Ver catálogo</ButtonText>
        <Icon src={OpenIcon} />
      </Container>
    </Button>
  )
} 