import { green, purple } from "@mui/material/colors"

export const lightText = "#F8F8F8"

export const darkText = "#000000"

export const menuText = "#130C4F"

export const titleText = "#040416"

export const white = "#FFFFFF"

export const backgroundColors = {
  red: ["#FCF9F7", "#F0E3DB", "#EAD8CD", "#E4CEBF"],
  green: ["#EEF8ED", "#DDF0DB", "#CFEACD"],
  purple: ["#A499FF", "#8D80FF"],
  grey: ["#948FBF"]
}

export const buttonColors = {
  green: "#46c63c",
  purple: "#675EBA"
}